<template>
    <LiefengCascader :dataScopeList="['44-4401-440106-440106008','44-4401-440106-440106009']"></LiefengCascader>
</template>

<script>
//@route('/testcascader');
import LiefengCascader from '@/components/LiefengCascader'
   export default {
       data() {
            return {
              
           }
        },
        components: {
            LiefengCascader
        }
      
    }
</script>
    
<style scoped lang='less'>
    
</style>